import { OrgId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import _ from "lodash";

export async function reports__server__getScheduledPaymentsByMonthReport(p: { orgId: OrgId }) {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool } = getServerHelpers();

  const data = await getAppPgPool().query(
    `select ROUND(sum((item ->> 'amountDueCents')::numeric -
       (item ->> 'derivedTotalAmountPaidCentsBeforeAllFees')::numeric) / 100.0, 2) as amount,
       TO_CHAR(TO_TIMESTAMP(cast(item ->> 'dueDateMS' as numeric) / 1000), 'MM') as month,
       TO_CHAR(TO_TIMESTAMP(cast(item ->> 'dueDateMS' as numeric) / 1000), 'YYYY') as year
from mirror_orginvoice
where item ->> 'orgId' = $1
  and item ->> 'thisInvoicePaidInFullDateMS' = '0'
  and item ->> 'type' in
      ( 'manual-payment-plan-installment', 'registration-payment-plan-installment')
group by month, year order by year desc, month desc;`,
    [p.orgId]
  );

  return data.rows.map(a => {
    return {
      amount: parseFloat(a["amount"]),
      month: parseInt(a["month"]) - 1,
      year: parseInt(a["year"])
    };
  });
  // SERVER_ONLY_TOGGLE
}

reports__server__getScheduledPaymentsByMonthReport.auth = async (req: any) => {
  await validateToken(req);
};
